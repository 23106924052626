import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipe from "photoswipe";

const lightbox = new PhotoSwipeLightbox({
  gallery: ".lightbox",
  children: "a",
  pswpModule: PhotoSwipe,
  closeTitle: 'Afsluiten',
  arrowPrevTitle: 'Vorige',
  arrowNextTitle: 'Volgende',
  errorMsg: 'De afbeelding kon niet worden geladen'
});

document.addEventListener("DOMContentLoaded", function() {
  lightbox.init();
});